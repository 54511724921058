import React from 'react';
import logo from './namemark.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
      </header>
      <div class="splash">
      <div class="logo">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1063.87 185.28">
          <title>Asset 3</title>
          <g>
            <circle cx="722.95" cy="80.78" r="73.5" fill="#0071bc"/>
            <text class="namemark" transform="translate(6.45 95.48)" font-size="124" stroke="#0071bc" font-family="Shelby">Mic
            <tspan x="142.47" y="0" letter-spacing="-0.01em">h</tspan
            ><tspan x="187.24" y="0">a</tspan>
            <tspan x="226.54" y="0" letter-spacing="0.01em">e</tspan>
            <tspan x="256.18" y="0">lLangmack </tspan>
            <tspan class="com">
            <tspan x="659.04" y="0" stroke="#f2f2f2">c</tspan>
            <tspan x="685.32" y="0" stroke="#f2f2f2" letter-spacing="0.01em">o</tspan>
            <tspan x="717.31" y="0" stroke="#f2f2f2">m</tspan>
            </tspan>
            </text>
          </g>
        </svg>
        </div>
        <p>
         <a href="mailto:mail@langmack@me.com" class="email-button">Say Hello</a>
        </p>
  </div>
</div>
  );
}

export default App;
